<template>
  <div>
    <Breadcrumbs />
    <div class="search-page">
      <div class="container">
        <div class="row">
          <SearchProductFilter />
          <ProductsListingSearch class="col-lg-9" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@config";
import Breadcrumbs from "@/base/core/components/common/Breadcrumbs";
import SearchProductFilter from "@/base/core/components/SearchProductFilter";
import ProductsListingSearch from "@/base/core/components/ProductsListingSearch";
import { Logger } from "@storefront/core/lib/logger";
import { extractFilters } from "@storefront/core/router/helpers/cleanPath.js";

export default {
  name: "CategoryOverviews",
  components: {
    // BaseLayout,
    Breadcrumbs,
    SearchProductFilter,
    ProductsListingSearch,
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Search",
      // all titles will be injected into this template
    };
  },
  computed: {
    searchWord() {
      if (typeof this.$route.query.q != "undefined") {
        return this.$route.query.q;
      } else {
        return "";
      }
    },
  },
  watch: {
    searchWord() {
      if (this.searchWord != "") {
        this.$store.dispatch("search/load", { search: this.searchWord });
        const bcrumb = { current: this.$route.query.q, routes: [] };
        this.$store.commit("breadcrumbs/set", bcrumb);

        const { path } = this.$route;
        const query = { ...this.$route.query };
        const pageSize = config.products?.perPage;
        const perPage = this.$store.getters["search/getProductsPerPage"];

        if (perPage != pageSize) query["page-size"] = perPage;
        this.$router.push({ path: path, query: query });
      }
    },
  },
  async mounted() {
    if (this.searchWord != "") {
      const filter = extractFilters(this.$route);
      await this.$store.dispatch("search/load", {
        search: this.searchWord,
        filter: filter,
      });
    }
    const bcrumb = { current: this.$route.query.q, routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  destroyed() {
    this.$store.commit("search/setLoadProducts", false);
    Logger.debug(
      "DESTROYED",
      "Search.vue",
      this.$store.getters["search/getLoadProducts"]
    )();
  },

  data() {
    return {
      //    bannerBg: bannerImage,
      //categories: mockCategory,
    };
  },
};
</script>

<style lang="scss" scoped></style>
