<template>
  <div
    class="
      d-flex
      justify-content-end justify-content-md-between
      align-items-start align-items-xl-center
      content-filter
    "
  >
    <div
      class="selected-filters d-none d-md-flex flex-wrap align-items-center"
    ></div>
    <div class="select-wrapper">
      <b-form-select
        id="sorting"
        v-model="sorting"
        :options="selectOptions"
        class="select"
      />
    </div>
  </div>
</template>

<script>
import config from "@config";
export default {
  name: "SalePageContentFilter",
  computed: {
    selectOptions() {
      const sortOpt = [];
      config.sortingOptions.forEach((element) => {
        const option = {
          value: element,
          text: this.$t(`sortingOptions.${element}`),
        };
        sortOpt.push(option);
      });
      return sortOpt;
    },
    sorting: {
      get: function () {
        return this.$store.getters["sale/getSorting"];
      },
      set: function (val) {
        this.$store.commit("sale/setSorting", val);
      },
    },
  },
  data: () => ({
    filterType: "Merken",

    selectedFilterItems: [
      { id: "selectedFilter01", name: "Castelli" },
      { id: "selectedFilter02", name: "Nalini" },
      { id: "selectedFilter03", name: "Rogelli" },
      { id: "selectedFilter04", name: "21Virages" },
      { id: "selectedFilter05", name: "Alpinestars" },
    ],
  }),
};
</script>

<style lang="scss" scoped>

</style>
