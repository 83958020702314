<template>
  <div>
    <!-- <AdvertisingBanner class="col-12 my-30 mt-lg-0" /> -->
    <!-- <BestSellers /> -->
    <SearchPageContent />
  </div>
</template>

<script>
import SearchPageContent from "@/base/core/components/SearchPageContent";
// import AdvertisingBanner from "@/base/core/components/products/category-product/AdvertisingBanner";
export default {
  name: "ProductsListingSearch",
  components: {
    //BestSellers,
    SearchPageContent,
    // AdvertisingBanner,
  },
  computed: {},
};
</script>

<style lang="scss" scoped></style>
