<template>
  <b-container>
    <div class="compare-page">
      <h1>{{ $t("compare_product") }}</h1>
      <div v-if="products.length == 0">
        <div class="no-data-found">
          <img src="@/base/assets/data-not-found.png" />
          <p>{{ $t("no_data_found") }}</p>
        </div>
      </div>
      <div v-else class="compare-wrap">
        <b-row>
          <b-col lg="3" v-show="!mobileCheck">
            <div class="compare-title">
              <div
                class="blank-space"
                :style="{ height: topBoxHeight && topBoxHeight + 25 + 'px' }"
              ></div>
              <ul class="attribute-title">
                <li
                  v-for="(attr, idx) in infoAttrKeys"
                  :key="attr.attribute_id"
                  :style="{
                    height:
                      sideTitlesHeight && sideTitlesHeight[idx] + 1 + 'px',
                  }"
                >
                  <span>
                    {{ attr.label }}
                  </span>
                </li>
              </ul>
            </div>
          </b-col>
          <b-col lg="9">
            <div class="compare-slider">
              <ClientOnly>
                <VueSlickCarousel
                  v-bind="settings"
                  class="product-multi-slider compare-list"
                >
                  <div
                    class="pm-slider-box"
                    v-for="(product, idx) in products"
                    :key="product.sku"
                  >
                    <div class="pm-box">
                      <div
                        class="top"
                        :ref="'top-box' + idx"
                        :style="{
                          height: topBoxHeight && topBoxHeight + 25 + 'px',
                        }"
                      >
                        <div
                          class="remove-product"
                          @click="remove(product.sku)"
                        >
                          <b-icon icon="trash"></b-icon>
                        </div>

                        <div class="img-wrap">
                          <div
                            class="pm-img"
                            :style="`background-image: url('${
                              product.thumbnail.medium
                                ? product.thumbnail.medium
                                : product.thumbnail.url
                            }');`"
                          ></div>
                        </div>

                        <div class="pm-more">
                          <div class="pm-other-info">
                            <b-link :to="`/${product.url_key}`" class="pm-card"
                              ><div class="pm-name">{{ product.name }}</div>
                            </b-link>

                            <div
                              data-bv-show="inline_rating"
                              :data-bv-product-id="product.sku"
                              :data-bv-redirect-url="product.url_key"
                            ></div>

                            <div class="store-wrap">
                              <DeliveryTime :data="product.DeliveryTime" />

                              <PickupStores
                                :product="product"
                                :cardType="'pickup'"
                              />
                            </div>

                            <span
                              class="pm-price"
                              v-if="!product.price_range.maximum_price"
                            >
                              {{
                                formatPrice(
                                  product.price_range.minimum_price.final_price
                                    .value
                                )
                              }}
                            </span>

                            <div class="d-flex justify-content-between no-cart">
                              <div
                                class="price-bar"
                                v-if="product.price_range.maximum_price"
                              >
                                <div
                                  class="price-bar-inner"
                                  v-if="
                                    product.price_range.minimum_price
                                      .final_price.value ==
                                    product.price_range.maximum_price
                                      .final_price.value
                                  "
                                >
                                  <div
                                    v-if="
                                      product.price_range.minimum_price.discount
                                        .percent_off >= 5
                                    "
                                    class="price-selection"
                                  >
                                    <span class="normal-price">
                                      <span>{{ $t("advice_price") }}:</span>
                                      <span class="original-price">
                                        <span class="price-stroke"></span>
                                        {{
                                          formatPrice(
                                            product.price_range.minimum_price
                                              .regular_price.value
                                          )
                                        }}
                                      </span></span
                                    >
                                  </div>

                                  <div class="price-selection">
                                    <span class="currentPrice">
                                      {{
                                        formatPrice(
                                          product.price_range.minimum_price
                                            .final_price.value
                                        )
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div
                                v-if="
                                  product.price_range.maximum_price &&
                                  product.price_range.minimum_price.final_price
                                    .value !=
                                    product.price_range.maximum_price
                                      .final_price.value
                                "
                                class="d-block product-card--price"
                              >
                                {{
                                  formatPrice(
                                    product.price_range.minimum_price
                                      .final_price.value
                                  )
                                }}
                                -
                                {{
                                  formatPrice(
                                    product.price_range.maximum_price
                                      .final_price.value
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="bottom">
                        <div class="extra-info">
                          <ul class="attribute" :ref="`attr-${idx}`">
                            <li
                              v-for="(info, idx1) in productInfoAttr(
                                product.sku
                              )"
                              :key="idx1"
                              :style="{
                                height:
                                  sideTitlesHeight &&
                                  sideTitlesHeight[idx1] + 1 + 'px',
                              }"
                            >
                              <div class="mobile-title" v-show="mobileCheck">
                                {{ infoAttrKeys[idx1].label }}
                              </div>
                              <span>{{ info }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </VueSlickCarousel>
              </ClientOnly>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-container>
</template>

<script>
import config from "@config";
import ClientOnly from "vue-client-only";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { Logger } from "@storefront/core/lib/logger";
import { isMobile, isTablet } from "mobile-device-detect";

import PickupStores from "@/base/core/components/products/product-detail/PickupStores.vue";
import DeliveryTime from "@/base/core/components/products/product-detail/DeliveryTime.vue";

export default {
  name: "Compare",
  components: {
    ClientOnly,
    VueSlickCarousel,
    PickupStores,
    DeliveryTime,
  },
  data: () => ({
    topBoxHeight: null,
    sideTitlesHeight: null,
    settings: {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 0,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    },
  }),
  mounted() {
    let topBoxHeight;
    const heights = {};

    this.$nextTick(() => {
      let self = this;

      const imgAttrs = Object.keys(self.$refs).filter((item) =>
        item.startsWith("top-box")
      );

      for (const ref of imgAttrs) {
        if (topBoxHeight) {
          if (topBoxHeight < self.$refs[ref].clientHeight) {
            topBoxHeight = self.$refs[ref].clientHeight;
          }
        } else {
          topBoxHeight = self.$refs[ref].clientHeight;
        }
      }

      this.topBoxHeight = topBoxHeight;

      const attributes = Object.keys(self.$refs).filter((item) =>
        item.startsWith("attr-")
      );

      for (const ref of attributes) {
        self.$refs[ref].children.forEach((child, idx) => {
          if (heights[idx]) {
            if (heights[idx] < child.clientHeight) {
              heights[idx] = child.clientHeight;
            }
          } else {
            heights[idx] = child.clientHeight;
          }
        });
      }
      this.sideTitlesHeight = heights;
    });
  },
  computed: {
    products() {
      return this.$store.getters["product/getProductCompare"];
    },
    infoAttrKeys() {
      return this.products.length
        ? this.products[0].information_attributes
        : [];

      // if (this.products.length) {
      //   let infoAttrs = this.products[0].information_attributes;

      //   this.products.forEach((product) => {
      //     product.information_attributes.forEach((item) => {
      //       if (item.value.toUpperCase().startsWith("X")) {
      //         infoAttrs = infoAttrs.filter(
      //           (item1) => item1.attribute_id != item.attribute_id
      //         );
      //       }
      //     });
      //   });
      //   return infoAttrs;
      // }
      // return [];
    },
    mobileCheck() {
      if (isMobile || isTablet) {
        return true;
      }
      return false;
    },
    availability() {
      if (this.product.__typename == "SimpleProduct") {
        const locations = this.product.store_availability.map((item) => ({
          ...item,
          store: {
            name: item.store.name.replace("EP:Tummers ", ""),
          },
        }));

        let newLocations = [];

        // let sortVal = ["roermond","Panningen","tegelen","echt","heythuysen","horst","weert","sittard"]

        let sortVal = config.pickupSortOptions;
        sortVal.forEach((item) => {
          const index = locations.findIndex(
            (location) =>
              location.store.name.toLowerCase() == item.toLowerCase()
          );
          if (index > -1) newLocations.push(locations.splice(index, 1)[0]);
        });

        newLocations = [...newLocations, ...locations];

        return newLocations;
      }
      if (this.product.__typename == "ConfigurableProduct") {
        Logger.debug(
          "availability",
          "ProductParameters",
          this.product.__typename
        )();
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            // return found.product.store_availability;
            return found.product.store_availability.map((item) => ({
              ...item,
              store: {
                name: item.store.name.replace("EP:Tummers ", ""),
              },
            }));
          }
        }
      }

      return [];
    },
    totalInStockAvailability() {
      return this.product.store_availability.reduce((acc, item) => {
        if (item.has_stock) return (acc += 1);
        return acc;
      }, 0);
    },
  },
  methods: {
    productInfoAttr(sku) {
      const product = this.products.find((prod) => prod.sku === sku);
      const newData = [];

      this.infoAttrKeys.forEach((element) => {
        const attribute = product.information_attributes.find(
          (attr) => attr.attribute_id == element.attribute_id
        );
        if (attribute && !attribute.value.toUpperCase().startsWith("X")) {
          newData.push(attribute.value);
        } else {
          newData.push("-");
        }
      });

      return newData;
    },
    async remove(sku) {
      let retval = await this.$store.commit(
        "product/removeProductFromCompare",
        sku
      );
      Logger.debug("retval", "removeFromCompare", retval)();
      if (retval == false) {
        const msg = {
          type: "danger",
          title: "",
          text: "something went wrong",
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
    formatPrice(price) {
      if (price % 1 === 0) {
        return "€" + price + ",-";
      }
      return "€" + price.toFixed(2).replace(".", ",");
    },
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
      title: "Vergelijk",
      // all titles will be injected into this template
    };
  },
};
</script>

<style>
</style>
