<template>
  <div>
    <Breadcrumbs />
    <div class="container">
      <div class="row">
        <SaleProductFilter class="col-lg-3" />
        <ProductsListingSale class="col-lg-9" />
      </div>
    </div>
  </div>
</template>

<script>
// import BaseLayout from "@/base/core/components/common/BaseLayout";
import Breadcrumbs from "@/base/core/components/common/Breadcrumbs";
import SaleProductFilter from "@/base/core/components/SaleProductFilter";
import ProductsListingSale from "@/base/core/components/ProductsListingSale";
import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "CategoryOverviews",
  components: {
    // BaseLayout,
    Breadcrumbs,
    SaleProductFilter,
    ProductsListingSale,
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Sale",
      // all titles will be injected into this template
    };
  },
  computed: {},
  async created() {
    Logger.debug("created", "sale.vue", "YEAHHHHHHHHH")();
    const filter = '{ sale:{eq: "1" }}';
    await this.$store.dispatch("sale/load", { filter: filter });
  },
  mounted() {
    const bcrumb = { current: "Sale", routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },

  data() {
    return {
      //    bannerBg: bannerImage,
      //categories: mockCategory,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
