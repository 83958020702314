<template>
  <div class="col-lg-3">
    <div class="filter-wrapper">
      <div class="filter fancy-scroll">
        <b-link class="filter-opener d-lg-none" @click.prevent="toggleFilter"
          >Filters<span class="plus">+</span>
        </b-link>
        <div
          class="filter-overlay"
          @click.prevent="toggleFilter"
          v-bind:class="{ show: filterExpanded }"
        ></div>
        <div
          class="fake-overlay"
          v-if="overlayIsReady"
          :class="{ show: filterExpanded }"
        ></div>
        <transition name="slide-fade">
          <div class="filter-collapse" :class="{ show: filterExpanded }">
            <SearchProductFilterContent @clicked="toggleFilter" />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import SearchProductFilterContent from "./SearchProductFilterContent.vue";
import { isServer } from "@storefront/core/helpers";
export default {
  name: "SearchProductFilter",
  components: {
    SearchProductFilterContent,
  },
  props: {
    dontShowActiveFilters: {
      type: Boolean,
    },
  },

  created() {
    if (!isServer) {
      window.addEventListener("resize", this.resize);
    }
  },
  mounted() {
    this.overlayIsReady = true;
  },
  destroyed() {
    if (!isServer) {
      window.removeEventListener("resize", this.resize);
    }
  },
  methods: {
    resize() {
      if (window.innerWidth > 991) this.filterExpanded = false;
    },
    toggleFilter() {
      this.filterExpanded = !this.filterExpanded;
    },
    removeSelectedItem(id) {
      const index = this.filterItemsSelected.findIndex(
        (item) => item.id === id
      );
      if (index > -1) {
        this.filterItemsSelected[index].isChecked = false;
      }
    },
  },
  data() {
    return {
      filterExpanded: false,
      overlayIsReady: false,
    };
  },
};
</script>

<style lang="scss">
.b-sidebar > .b-sidebar-footer {
  margin-bottom: 60px;
}
</style>
