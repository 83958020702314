<template>
  <div>
    <Breadcrumbs />
    <section class="static pt-7 our-stores">
      <b-container>
        <!-- <h1>Blog</h1> -->
        <b-row v-if="allStores.length > 0">
          <b-col
            lg="4"
            md="6"
            sm="12"
            class="blog_box"
            v-for="(store, idx) of allStores"
            :key="'store' + idx"
          >
            <div class="inner_wrapper">
              <div class="img_wrapper">
                <div
                  class="banner-area"
                  v-if="store.images.length > 0"
                  :style="`background-image: url('${store.images[0].url}');`"
                ></div>
              </div>
              <div class="cn_wrapper">
                <h2 class="title">{{ store.name }}</h2>
                <p>
                  {{ store.street }}, {{ store.postcode }} {{ store.city }}
                  <br />
                  Tel. {{ store.phone }}
                </p>
                <b-link :to="'/onze-winkels/' + store.url_key">
                  Meer informatie en openingstijden
                  <b-icon
                    icon="chevron-double-right"
                    aria-hidden="true"
                  ></b-icon>
                </b-link>
              </div>
            </div>
          </b-col>
        </b-row>
        <div v-else>No stores found</div>
      </b-container>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from "@/base/core/components/common/Breadcrumbs";

export default {
  components: {
    Breadcrumbs,
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "title",
          content:
            this.currentPage &&
            (this.currentPage.meta_title ?? this.currentPage.title ?? ""),
        },
        {
          name: "keywords",
          content: this.currentPage && (this.currentPage.meta_keywords ?? ""),
        },
        {
          name: "description",
          content:
            this.currentPage && (this.currentPage.meta_description ?? ""),
        },
        {
          property: "og:title",
          content:
            this.currentPage &&
            (this.currentPage.meta_title ?? this.currentPage.title ?? ""),
        },

        {
          property: "og:description",
          content:
            this.currentPage && (this.currentPage.meta_description ?? ""),
        },
        {
          name: "twitter:title",
          content:
            this.currentPage &&
            (this.currentPage.meta_title ?? this.currentPage.title ?? ""),
        },

        {
          name: "twitter:description",
          content:
            this.currentPage && (this.currentPage.meta_description ?? ""),
        },
      ],
      title:
        this.currentPage &&
        (this.currentPage.meta_title ?? this.currentPage.title ?? ""),
    };
  },
  computed: {
    allStores() {
      return this.$store.getters["stores1/getStores"];
    },
    currentPage() {
      return this.$store.getters["cmsPage/getCurrentPage"];
    },
  },
  mounted() {
    const bcrumb = {
      current: "Onze winkels",
      routes: [],
    };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
};
</script>

<style lang="scss">
.our-stores {
  @media (max-width: 767px) {
    padding: 30px 0;
  }
}
.blog_box {
  margin-bottom: 40px;
  .img_wrapper {
    height: 110px;
    background: #eee;
    .banner-area {
      height: 100%;
      background-position: center;
      background-size: cover;
    }
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-top: 8px;
  }
  .cn_wrapper {
    p {
      min-height: 50px;
      @media (max-width: 767px) {
        min-height: auto;
      }
    }
  }
}
</style>
