<template>
  <div class="all-category-list-page">
    <!-- <p>{{ getMenuItems }}</p> -->
    <b-container>
      <b-row>
        <b-col lg="12">
          <div class="title">
            <label> {{ $t("our_range") }} </label>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="3">
          <ul class="cat-sidebar">
            <li v-for="category in getMenuItems" :key="category.id">
              <a :href="`#cat-${category.id}`"> {{ category.itemName }}</a>
            </li>
          </ul>
        </b-col>
        <b-col lg="9">
          <div class="category-wrap">
            <div
              v-for="category in getMenuItems"
              :id="`cat-${category.id}`"
              :key="category.id"
              class="category-box"
            >
              <div v-if="category.dropMenu.length > 0">
                <h2>{{ category.itemName }}</h2>
                <div class="single-group">
                  <div
                    v-for="subCatagory in category.dropMenu[0]"
                    :key="subCatagory.id"
                    class="category-box-inner"
                  >
                    <h3>
                      <b-link :to="subCatagory.linkTo">
                        {{ subCatagory.name }}</b-link
                      >
                    </h3>
                    <ul class="third-category">
                      <li
                        v-for="subSubCatagory in subCatagory.dropMenu"
                        :key="subSubCatagory.id"
                      >
                        <b-link :to="subSubCatagory.url_path">
                          {{ subSubCatagory.name }}
                        </b-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <h2 v-else>
                <b-link :to="category.linkTo"> {{ category.itemName }}</b-link>
              </h2>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AllCategories",
  async created() {},

  computed: {
    ...mapGetters({
      getMenuItems: "menu/getMenuItems",
    }),
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "title",
          content: this.$t("our_range"),
        },
        {
          name: "keywords",
          content: this.$t("our_range"),
        },
        {
          name: "description",
          content: this.$t("our_range"),
        },
        {
          property: "og:title",
          content: this.$t("our_range"),
        },
        {
          property: "og:description",
          content: this.$t("our_range"),
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("our_range"),
      // all titles will be injected into this template
    };
  },
};
</script>

<style>
</style>
