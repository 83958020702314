<template>
  <div v-if="currentstore != null">
    <Breadcrumbs />
    <b-container>
      <!-- <h1>Blog</h1> -->
      <div class="store-banner-sec" v-if="currentstore.images.length > 0">
        <img class="innerbanner" :src="currentstore.images[0].url" />
      </div>
      <div class="store-inner">
        <b-row v-if="currentstore != null">
          <b-col lg="3">
            <div class="tr_ds_box">
              <h3>{{ currentstore.name }}</h3>
              <address v-if="currentstore != null">
                {{ currentstore.street }} <br />
                {{ currentstore.postcode }} {{ currentstore.city }} <br />
                {{ currentstore.phone }}<br />
                {{ currentstore.email }}
              </address>
            </div>
            <div class="time_DC_wrapper">
              <h3>Openingstijden</h3>
              <div
                class="time_row"
                v-for="(openingTimeDatum, idx) of openingTimes"
                :key="idx"
              >
                <template v-if="openingTimeDatum">
                  <div
                    class="d-flex"
                    v-for="(item, index) in openingTimeDatum.hours"
                    :key="`${openingTimeDatum.label}-${index}`"
                  >
                    <span class="label" v-if="index == 0">{{
                      $t(openingTimeDatum.label)
                    }}</span>
                    <span v-if="item.from != null" class="time"
                      >{{ item.from }} - {{ item.to }}</span
                    >
                    <span v-else>
                      {{ $t("closed") }}
                    </span>
                  </div>
                </template>
              </div>
            </div>
          </b-col>
          <b-col lg="9">
            <b-row>
              <b-col :lg="currentstore.manager ? '8' : '12'"
                ><div class="store-content">
                  <h4 class="title">{{ currentstore.name }}</h4>
                </div>
                <div class="desc" v-html="currentstore.description"></div>
              </b-col>
              <b-col lg="4" v-if="currentstore.manager"
                ><div class="ofcs_box">
                  <img :src="currentstore.manager.image" alt="" />
                  <h3 v-if="currentstore.manager">
                    {{ currentstore.manager.name }}
                  </h3>
                  <p>{{ currentstore.manager.role }}</p>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
// import BaseLayout from "@/base/core/components/common/BaseLayout";
import map from "@/base/assets/map.jpg";
import Breadcrumbs from "@/base/core/components/common/Breadcrumbs";

export default {
  name: "OurShop",
  components: {
    Breadcrumbs,
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { name: "title", content: this.currentstore && this.currentstore.name },
        {
          name: "keywords",
          content: this.currentstore && (this.currentstore.name ?? ""),
        },
        {
          name: "description",
          content: this.currentstore && (this.currentstore.name ?? ""),
        },
        {
          property: "og:title",
          content: this.currentstore && (this.currentstore.name ?? ""),
        },
        {
          property: "og:description",
          content: this.currentstore && (this.currentstore.name ?? ""),
        },
        {
          property: "og:image",
          content:
            this.currentstore &&
            this.currentstore.images &&
            this.currentstore.images[0] &&
            this.currentstore.images[0].url,
        },
        {
          name: "twitter:title",
          content: this.currentstore && (this.currentstore.name ?? ""),
        },
        {
          name: "twitter:description",
          content: this.currentstore && (this.currentstore.name ?? ""),
        },
        {
          name: "twitter:image",
          content:
            this.currentstore &&
            this.currentstore.images &&
            this.currentstore.images[0] &&
            this.currentstore.images[0].url,
        },
      ],
      title: this.currentstore && (this.currentstore.name ?? ""),
    };
  },
  data() {
    return {
      map: map,
      largeScreen: false,
    };
  },
  mounted() {
    const bcrumb = {
      current: this.currentstore.name,
      routes: [{ name: "Onze winkels", route_link: "/onze-winkels" }],
    };
    this.$store.commit("breadcrumbs/set", bcrumb);

    window.addEventListener("resize", this.setLargeScreen);
    window.addEventListener("load", this.setLargeScreen);
    this.setLargeScreen();
  },
  destroyed() {
    window.removeEventListener("resize", this.setLargeScreen);
    window.removeEventListener("load", this.setLargeScreen);
  },
  computed: {
    currentstore() {
      return this.$store.getters["stores1/getStoreByCode"](
        this.$route.params.code
      );
    },
    openingTimes() {
      if (this.currentstore != null) {
        return this.currentstore.openingHours;
      }
      return null;
    },
  },
  watch: {
    currentstore() {
      if (this.currentstore != null) {
        const bcrumb = {
          current: this.currentstore.name,
          routes: [{ name: "Onze winkels", route_link: "/onze-winkels" }],
        };
        this.$store.commit("breadcrumbs/set", bcrumb);
      }
    },
  },
  methods: {
    setLargeScreen() {
      this.largeScreen = window.innerWidth > 991;
    },
  },
};
</script>

<style lang="scss" scoped>
.store-inner {
  padding: 0 0 30px;
  .desc {
    font-size: 13px;
  }
}
.store-banner-sec {
  margin-bottom: 40px;
}
.tr_ds_box {
  h3 {
    font-size: 20px;
    font-weight: 600;
  }
  address {
    font-size: 13px;
  }
}
.time_DC_wrapper {
  h3 {
    font-size: 20px;
    font-weight: 600;
  }
  .time_row {
    font-size: 13px;
    .label {
      width: 100px;
    }
  }
}
</style>
