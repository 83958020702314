<template>
  <div class="search-page-filter">
    <div class="category filter-box" v-if="!dontShowActiveFilters">
      <span class="filter--block-heading">{{ searchWord }}</span>
    </div>
    <div
      class="active-filters filter-box cst-collapse"
      v-if="isPriceFilterActive || filterItemsSelected.length > 0"
    >
      <b-link
        class="filter--block-heading filter--collapse"
        v-b-toggle.active-filters
        >{{ $t("used_filters") }}</b-link
      >

      <b-collapse visible id="active-filters">
        <ul class="active-filters-list">
          <li v-if="isPriceFilterActive">
            <b-link @click="removePriceFilter">
              <i class="fas fa-times"></i>
            </b-link>
            <span class="active-filter--name">
              {{ $t("Price") }}:
              {{ priceFilter[0] + " - " + priceFilter[1] }}
            </span>
          </li>
          <li
            v-for="(activeFilterItem, index) of filterItemsSelected"
            :key="index"
          >
            <b-link @click="removeSelectedItem(activeFilterItem.value)">
              <i class="fas fa-times"></i>
            </b-link>
            <span class="active-filter--name">
              {{ activeFilterItem.type_label }}:
              {{ activeFilterItem.label }}
            </span>
          </li>
        </ul>
      </b-collapse>
    </div>
    <div
      class="filter-box"
      v-for="(prodFilter, index) of productFilters"
      :key="prodFilter.attribute_code"
    >
      <div
        class="price cst-collapse"
        v-if="prodFilter.attribute_code == 'price'"
      >
        <b-link
          class="filter--block-heading collapse-opener"
          v-b-toggle.price-filter
        >
          <div class="tooltip-label">
            {{ $t("Price") }}

            <div
              class="spec-tooltip ml-2 cursor-pointer"
              @click.stop="
                prodFilter.tooltip != null && (activeTooltipId = index)
              "
            >
              <div :class="{ disabled: !prodFilter.tooltip }">
                <i class="fas fa-info-circle"></i>
              </div>
            </div>
          </div>
        </b-link>

        <b-modal
          :id="'modal' + index"
          :visible="activeTooltipId === index"
          @hidden="activeTooltipId = null"
          hide-header
          hide-footer
          centered
        >
          <div class="relative">
            <div class="close-modal" @click="activeTooltipId = null">
              <span><i class="fa fa-times"></i></span>
            </div>
            <div class="spectmodal-content fancy-scroll">
              <div v-html="prodFilter.tooltip"></div>
            </div>
          </div>
        </b-modal>

        <b-collapse visible id="price-filter">
          <div class="range-slider">
            <div class="range-values d-flex justify-content-between">
              <span
                ><input
                  ref="priceFilter"
                  type="number"
                  :min="priceFilterMin"
                  :max="priceFilterMax"
                  @input="debounceMin"
                  :value="priceRange[0]"
              /></span>
              <span
                ><input
                  ref="priceFilter"
                  type="number"
                  :min="priceFilterMin"
                  :max="priceFilterMax"
                  @input="debounceMax"
                  :value="priceRange[1]"
              /></span>
            </div>
            <ClientOnly>
              <VueRangeSlider
                :min="priceFilterMin"
                :max="priceFilterMax"
                :lazy="true"
                :formatter="formatter"
                v-model="priceRange"
                :logarithmic="true"
                tooltip="active"
                :enableCross="false"
                ref="rangeSlider"
              >
              </VueRangeSlider>
              <!-- :enable-cross="enableCross" -->
            </ClientOnly>
          </div>
        </b-collapse>
      </div>

      <div
        class="brands cst-collapse"
        v-if="
          prodFilter.attribute_code != 'size' &&
          prodFilter.attribute_code != 'price'
        "
      >
        <b-link
          class="filter--block-heading collapse-opener"
          v-b-toggle="`brands-filter-${prodFilter.attribute_code}`"
        >
          <div class="tooltip-label">
            {{ prodFilter.label }}
            <div
              class="spec-tooltip ml-2 cursor-pointer"
              @click.stop="
                prodFilter.tooltip != null && (activeTooltipId = index)
              "
            >
              <div :class="{ disabled: !prodFilter.tooltip }">
                <i class="fas fa-info-circle"></i>
              </div>
            </div>
          </div>
        </b-link>

        <b-modal
          :id="'modal' + index"
          :visible="activeTooltipId === index"
          @hidden="activeTooltipId = null"
          hide-header
          hide-footer
          centered
        >
          <div class="relative">
            <div class="close-modal" @click="activeTooltipId = null">
              <span><i class="fa fa-times"></i></span>
            </div>
            <div class="spectmodal-content fancy-scroll">
              <div v-html="prodFilter.tooltip"></div>
            </div>
          </div>
        </b-modal>

        <b-collapse
          visible
          :id="`brands-filter-${prodFilter.attribute_code}`"
          class="brands-filter"
        >
          <div
            v-for="(brandsFilterItem, index) in prodFilter.options"
            :key="index"
          >
            <div v-if="index < 5">
              <div class="d-flex justify-content-between">
                <label
                  :for="brandsFilterItem.value"
                  class="d-flex align-items-center"
                >
                  <input
                    type="checkbox"
                    :name="brandsFilterItem.label"
                    :id="brandsFilterItem.value"
                    :key="brandsFilterItem.value"
                    :ref="brandsFilterItem.value"
                    :value="brandsFilterItem.value"
                    @click="
                      onChangeFilter($event, {
                        attribute_code: prodFilter.attribute_code,
                        type_label: prodFilter.label,
                        value: brandsFilterItem.value,
                        label: brandsFilterItem.label,
                      })
                    "
                  />
                  <span class="fake-input"></span>
                  <span class="fake-label">{{ brandsFilterItem.label }}</span>
                </label>
                <span class="number-available">
                  ({{ brandsFilterItem.count }})
                </span>
              </div>
            </div>
            <div v-else>
              <div
                v-if="readMore[prodFilter.attribute_code]"
                class="d-flex justify-content-between"
              >
                <label
                  :for="brandsFilterItem.value"
                  class="d-flex align-items-center"
                >
                  <input
                    type="checkbox"
                    :name="brandsFilterItem.label"
                    :id="brandsFilterItem.value"
                    :key="brandsFilterItem.value"
                    :ref="brandsFilterItem.value"
                    :value="brandsFilterItem.value"
                    @click="
                      onChangeFilter($event, {
                        attribute_code: prodFilter.attribute_code,
                        type_label: prodFilter.label,
                        value: brandsFilterItem.value,
                        label: brandsFilterItem.label,
                      })
                    "
                  />
                  <span class="fake-input"></span>
                  <span class="fake-label">{{ brandsFilterItem.label }}</span>
                </label>
                <span class="number-available">
                  ({{ brandsFilterItem.count }})
                </span>
              </div>
            </div>
          </div>
          <div
            style="width: 100%; text-align: left"
            v-if="prodFilter.options.length > 5"
          >
            <div v-if="!readMore[prodFilter.attribute_code]">
              <b-link
                class="filter--block-heading"
                style="display: block; cursor: pointer"
                @click="showMore(prodFilter.attribute_code)"
              >
                Toon meer
              </b-link>
            </div>
            <div v-if="readMore[prodFilter.attribute_code]">
              <b-link
                class="filter--block-heading"
                style="display: block; cursor: pointer"
                @click="showLess(prodFilter.attribute_code)"
              >
                Toon minder
              </b-link>
            </div>
          </div>
        </b-collapse>
      </div>
      <div class="size" v-if="prodFilter.attribute_code == 'size'">
        <b-link
          class="filter--block-heading filter--collapse mb-15"
          v-b-toggle.size-filter
          >Kies je maat</b-link
        >
        <b-collapse visible id="size-filter" class="size-filter">
          <ul class="available-sizes">
            <li
              v-for="(sizeFilterItem, index) in prodFilter.options"
              :key="index"
            >
              <label
                :for="sizeFilterItem.name"
                class="d-flex align-items-center"
              >
                <input
                  type="checkbox"
                  :id="sizeFilterItem.value"
                  :key="sizeFilterItem.value"
                  :name="sizeFilterItem.label"
                  :ref="sizeFilterItem.value"
                  :value="sizeFilterItem.value"
                  @change="
                    onChangeFilter($event, {
                      attribute_code: prodFilter.attribute_code,
                      type_label: prodFilter.label,
                      value: sizeFilterIte.value,
                      label: sizeFilterIte.label,
                    })
                  "
                />
                <span class="fake-input"></span>
                <span class="fake-label">{{ sizeFilterItem.name }}</span>
              </label>
            </li>
          </ul>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { Logger } from "@storefront/core/lib/logger";
import ClientOnly from "vue-client-only";
import VueRangeSlider from "vue-slider-component/dist-css/vue-slider-component.umd.min.js";
import { debounce } from "lodash";

export default {
  name: "ProductFilterSidebar",
  components: {
    ClientOnly,
    VueRangeSlider,
  },
  props: {
    dontShowActiveFilters: {
      type: Boolean,
    },
  },

  created() {
    this.min = 0;
    this.max = 250;
    this.enableCross = false;
    this.formatter = "€ {value}";
    this.tooltipDir = "bottom";
  },
  mounted() {
    this.priceRange = this.priceFilter;
    this.filterItemsSelected.forEach(
      (item) => (this.$refs[item.value][0].checked = true)
    );
  },
  methods: {
    debounceMin: debounce(function (event) {
      this.onPriceRangeMinChange(event);
    }, 300),

    debounceMax: debounce(function (event) {
      this.onPriceRangeMaxChange(event);
    }, 500),
    onPriceRangeMinChange(event) {
      if (
        Number(event.target.value) <= this.priceRange[1] &&
        Number(event.target.value) >= Number(event.target.min)
      ) {
        this.priceRange[0] = Number(event.target.value);
        this.processPricePriceRangeChange(this.priceRange);
        this.$refs.rangeSlider[0].setValue(this.priceRange);
      }
    },
    onPriceRangeMaxChange(event) {
      if (
        Number(event.target.value) >= this.priceRange[0] &&
        Number(event.target.value) <= Number(event.target.max)
      ) {
        this.priceRange[1] = Number(event.target.value);
        this.processPricePriceRangeChange(this.priceRange);
        this.$refs.rangeSlider[0].setValue(this.priceRange);
      }
    },
    processPricePriceRangeChange(newValue) {
      if (this.$refs.priceFilter) {
        const inputMin = this.$refs.priceFilter[0];
        if (inputMin && parseInt(newValue[0]) <= parseInt(newValue[1])) {
          this.$store.commit("search/setPriceFilter", [...newValue]);
          const { path } = this.$route;
          const query = { ...this.$route.query };

          if (this.isPriceFilterActive)
            query["filter-price"] = `${newValue[0]}-${newValue[1]}`;
          else delete query["filter-price"];
          delete query["page"];

          this.$router.replace({ path, query });
        }
      }
    },
    showMore(id) {
      this.$set(this.readMore, id, true);
    },
    showLess(id) {
      this.$set(this.readMore, id, false);
    },
    onChangeFilter(event, filter) {
      if (typeof filter != "undefined" && typeof filter.value != "undefined") {
        if (event.target.checked == true) {
          this.$store.commit("search/setActiveFilter", filter);
        } else {
          this.$store.commit("search/removeActiveFilter", filter);
        }
        this.updateUrlFilters();
      }
    },
    removeSelectedItem(id) {
      const filter = { value: id };
      if (this.$refs[id] && this.$refs[id][0]) {
        this.$refs[id][0].checked = false;
      }
      this.$store.commit("search/removeActiveFilter", filter);
      this.updateUrlFilters();
    },
    removePriceFilter() {
      const priceFilter = [this.priceFilterMin, this.priceFilterMax];
      this.$store.commit("search/setPriceFilter", priceFilter);

      const { path } = this.$route;
      const query = { ...this.$route.query };
      delete query["filter-price"];
      delete query["page"];

      this.$router.replace({ path, query });
    },
    updateUrlFilters() {
      const activeFilters = {};
      this.filterItemsSelected.forEach((val) => {
        if (activeFilters[val.attribute_code]) {
          activeFilters[val.attribute_code].push(val.value);
        } else {
          activeFilters[val.attribute_code] = [val.value];
        }
      });

      const { path } = this.$route;
      const query = { ...this.$route.query };

      for (const q in query) {
        if (q.startsWith("filter-") && q !== "filter-price") {
          delete query[q];
        }
      }
      for (const attr in activeFilters) {
        query[`filter-${attr}`] = activeFilters[attr].toString();
      }
      delete query["page"];

      this.$router.replace({ path, query });
    },
    toggleFilter(event) {
      this.$emit("clicked", event);
    },
  },
  data() {
    return {
      // value: [0, 200],
      activeTooltipId: null,
      sliderIsLoaded: false,
      bla: false,
      minAngle: 10,
      maxAngle: 30,
      filterExpanded: false,
      readMore: {},
      priceRange: [0, 0],
    };
  },
  computed: {
    filterItemsSelected() {
      return this.$store.getters["search/getActiveFilter"];
    },
    searchWord() {
      if (typeof this.$route.query.q != "undefined") {
        return this.$route.query.q;
      } else {
        return "";
      }
    },
    isPriceFilterActive() {
      return this.$store.getters["search/getIsPriceFilterActive"];
    },
    priceFilterMin() {
      return this.$store.getters["search/getPriceFilterMin"];
    },
    priceFilterMax() {
      return this.$store.getters["search/getPriceFilterMax"];
    },
    priceFilter() {
      return this.$store.getters["search/getPriceFilter"];
    },
    productFilters: {
      get: function () {
        return this.$store.getters["search/getProductFilters"];
      },
      set: function (val) {
        Logger.debug("set", "productFilters", val)();
        //this.$store.commit("category/setProductFilters", val);
      },
    },
    categories() {
      return this.category.children;
    },
  },
  updated() {
    if (this.filterItemsSelected && this.filterItemsSelected.length) {
      this.filterItemsSelected.forEach((filter) => {
        if (this.$refs[filter.value] && this.$refs[filter.value][0]) {
          this.$refs[filter.value][0].checked = true;
        }
      });
    }
  },
  watch: {
    filterItemsSelected: function () {
      //Logger.debug("filterItemsSelected", "watch", this.filterItemsSelected)();
    },
    priceFilter(value) {
      this.priceRange = value;
    },
    priceRange(newValue) {
      if (
        !(
          newValue[0] === this.priceFilter[0] &&
          newValue[1] === this.priceFilter[1]
        )
      ) {
        this.processPricePriceRangeChange(newValue);
      }
    },
  },
};
</script>

<style lang="scss"></style>
