<template>
  <div>
    <Breadcrumbs />
    <section class="static pt-7 blog-list">
      <b-container fluid class="p-0">
        <b-row class="mob-show m-0">
          <b-col cols="12" xl="3" lg="3" md="3" class="p-0">
            <ClientOnly>
              <VueSlickCarousel
                v-if="blogBanner"
                :arrows="false"
                :dots="false"
                :slidesToShow="1"
              >
                <div
                  class="cms-block-content"
                  v-for="(slide, index) in blogBanner.slides"
                  :key="index"
                >
                  <div
                    class="mobile-banner"
                    :style="`background-image: url('${slide.media.url}');`"
                  ></div>
                </div>
              </VueSlickCarousel>
            </ClientOnly>
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <b-row>
          <b-col cols="12" xl="3" lg="3" md="4">
            <h4 class="blog-title">Blog</h4>
            <div class="blog-search">
              <b-form-input
                type="text"
                :placeholder="$t('search_blog')"
                class="search-input"
                @keyup="searchBlog"
              ></b-form-input>
              <div class="search-btn">
                <i class="fa fa-search"></i>
              </div>
            </div>
            <ul class="filter-list">
              <li
                :class="{ active: activeCategory === null }"
                @click="$router.push({ name: 'blogs' })"
              >
                Alles
              </li>
              <li
                v-for="category of blogCategories"
                :key="category.category_id"
                @click="$router.push(`/blog/categorie/${category.identifier}`)"
                :class="{
                  active:
                    activeCategory &&
                    activeCategory.identifier === category.identifier,
                }"
              >
                {{ category.title }}
              </li>
            </ul>
          </b-col>
          <b-col cols="12" xl="9" lg="9" md="8">
            <b-row class="desktop-show">
              <b-col>
                <ClientOnly>
                  <VueSlickCarousel
                    v-if="blogBanner"
                    :arrows="false"
                    :dots="false"
                    :slidesToShow="1"
                  >
                    <div
                      class="cms-block-content"
                      v-for="(slide, index) in blogBanner.slides"
                      :key="index"
                    >
                      <div
                        class="blog-banner"
                        :style="`background-image: url('${slide.media.url}');`"
                      ></div>
                    </div>
                  </VueSlickCarousel>
                </ClientOnly>
              </b-col>
            </b-row>
            <div class="blog-grid">
              <div class="blog-sorting">
                <div class="select-wrapper">
                  <b-form-select
                    id="sorting"
                    v-model="sorting"
                    :options="blogSortingOption"
                    class="select"
                  />
                </div>
              </div>
              <b-row>
                <div v-if="isLoading" class="box">
                  <div class="loader"></div>
                </div>

                <template v-else-if="!isFilterActive && allBlogs.length > 0">
                  <b-col
                    v-for="(item, index) in allBlogs"
                    :key="index"
                    cols="12"
                    xl="4"
                    lg="4"
                    md="6"
                  >
                    <b-link class="" :to="'/blog/' + item.identifier">
                      <div class="blog-box">
                        <div class="blog-featured-image">
                          <img
                            :src="item.featured_image"
                            :alt="item.featured_img_alt"
                          />
                        </div>
                        <div class="blog-info">
                          <h4 class="">{{ item.title }}</h4>
                          <div class="meta-description">
                            <div class="blog-date">
                              <i class="fas fa-calendar-alt"></i>
                              <span>{{ blogtDate(item.creation_time) }} </span>
                            </div>
                          </div>
                          <div class="blog-desc">
                            <p v-html="item.short_filtered_content"></p>
                          </div>
                        </div>
                        <div class="blog-link">
                          {{ $t("show_more") }}
                        </div>
                      </div>
                    </b-link>
                  </b-col>
                </template>
                <template
                  v-else-if="isFilterActive && filteredBlogs.length > 0"
                >
                  <b-col
                    v-for="(item, index) in filteredBlogs"
                    :key="index"
                    cols="12"
                    xl="4"
                    lg="4"
                    md="6"
                  >
                    <b-link class="" :to="'/blog/' + item.identifier">
                      <div class="blog-box">
                        <div class="blog-featured-image">
                          <img
                            :src="item.featured_image"
                            :alt="item.featured_img_alt"
                          />
                        </div>
                        <div class="blog-info">
                          <h4 class="">{{ item.title }}</h4>
                          <div class="meta-description">
                            <div class="blog-date">
                              <i class="fas fa-calendar-alt"></i>
                              <span>{{ blogtDate(item.creation_time) }}</span>
                            </div>
                          </div>
                          <div class="blog-desc">
                            <p v-html="item.short_filtered_content"></p>
                          </div>
                        </div>
                        <div class="blog-link">
                          {{ $t("show_more") }}
                        </div>
                      </div>
                    </b-link>
                  </b-col>
                </template>
                <div class="no-data-found" v-else>
                  <p>{{ $t("no_data_found") }}</p>
                </div>
              </b-row>
              <div class="load-more">
                <div v-if="showLoadMoreBtn" @click="loadMore">
                  {{ $t("load_more") }}
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>
<script>
import config from "@config";
import ClientOnly from "vue-client-only";
import { Logger } from "@storefront/core/lib/logger";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import Breadcrumbs from "@/base/core/components/common/Breadcrumbs";
export default {
  name: "Blogs",
  components: {
    // BaseLayout,
    Breadcrumbs,
    VueSlickCarousel,
    ClientOnly,
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { name: "title", content: "EP:Tummers | Blog" },
        { name: "keywords", content: "EP:Tummers | Blog" },
        { name: "description", content: "EP:Tummers | Blog" },
      ],
      title: "EP:Tummers | Blog",
    };
  },
  data: () => ({
    isLoading: false,
    isFilterActive: false,
    activeCategory: null,
    sorting: "newest",
    searchTimer: null,
    allBlogs: [],
    filteredBlogs: [],
    currPage: 1,
    blogsPerPage: 6,
  }),
  async created() {
    await this.getAllBlogsCategory();

    const {
      name,
      params: { category_id },
    } = this.$route;

    if (name == "blog-category" && category_id) {
      this.getBlogsByCategoryId(category_id);
    } else {
      this.setAllBlogs();
    }
  },
  methods: {
    blogtDate(date) {
      let month = [
        "januari",
        "februari",
        "maart",
        "april",
        "mei",
        "juni",
        "juli",
        "augustus",
        "september",
        "oktober",
        "november",
        "december",
      ];
      let reviewDate = new Date(date);
      let dd = reviewDate.getDate();
      let mm = month[reviewDate.getMonth()];
      let yyyy = reviewDate.getFullYear();
      reviewDate = dd + " " + mm + " " + yyyy;
      return reviewDate;
    },
    setBreadCrumbs() {
      let activeCategoryName = "Alles";

      if (this.activeCategory != null) {
        activeCategoryName = this.activeCategory.title;
      }

      const bcrumb = {
        current: activeCategoryName,
        routes: [{ name: "Blog", route_link: "/blog" }],
      };
      this.$store.commit("breadcrumbs/set", bcrumb);
    },
    setAllBlogs() {
      const list = this.$store.getters["blog/getList"];
      this.allBlogs = [];
      list.forEach((element) => {
        element.items.forEach((item) => {
          const isExist = this.allBlogs.find(
            (el) => el.post_id === item.post_id
          );
          const currentLength = this.currPage * this.blogsPerPage;
          if (!isExist && this.allBlogs.length < currentLength) {
            this.allBlogs = [...this.allBlogs, item];
          }
        });
      });
      this.sortBlogs();
      this.setBreadCrumbs();
    },
    getAllBlogs() {
      this.currPage = 1;
      this.isFilterActive = false;
      this.activeCategory = null;
      this.setAllBlogs();
    },
    async getAllBlogsCategory() {
      this.isLoading = true;
      await this.$store.dispatch("blog1/loadBlogCategories");
      this.isLoading = false;
    },
    async getBlogsByCategoryId(identifier) {
      const category = this.blogCategories.find(
        (item) => item.identifier === identifier
      );

      if (category != null) {
        const categoryId = category.category_id;
        this.activeCategory = category;
        this.isFilterActive = true;

        this.isLoading = true;
        await this.$store.dispatch("blog1/loadBlogsByFilter", { categoryId });
        this.isLoading = false;

        this.currPage = 1;
        this.filteredBlogs = this.allFilteredBlogs.slice(0, this.blogsPerPage);

        this.sortBlogs();
        this.setBreadCrumbs();
      }
    },
    async loadMore() {
      if (!this.isFilterActive) {
        this.currPage++;
        if (this.currPage * this.blogsPerPage > this.allBlogs.length) {
          await this.$store.dispatch("blog/goToPage", {
            pageNr: this.currPage,
          });
        }
        this.setAllBlogs();
      } else {
        this.filteredBlogs = this.allFilteredBlogs.slice(
          0,
          this.filteredBlogs.length + this.blogsPerPage
        );
      }
      this.sortBlogs();
    },
    async searchBlog(event) {
      if (this.searchTimer != null) {
        clearTimeout(this.searchTimer);
      }

      this.searchTimer = setTimeout(async () => {
        const searchVal = event.target.value;
        if (searchVal.length > 0) {
          this.isFilterActive = true;

          let payload = { searchVal };

          if (this.activeCategory !== null) {
            const categoryId = this.activeCategory.category_id;
            payload = { ...payload, categoryId };
          }

          this.isLoading = true;
          await this.$store.dispatch("blog1/loadBlogsByFilter", payload);
          this.isLoading = false;

          this.filteredBlogs = this.allFilteredBlogs.slice(
            0,
            this.blogsPerPage
          );
        } else {
          if (this.activeCategory !== null) {
            this.getBlogsByCategoryId(this.activeCategory.identifier);
          } else {
            this.isFilterActive = false;
            this.setAllBlogs();
          }
        }
      }, 300);
    },
    sortBlogs() {
      if (!this.isFilterActive) {
        const blogs = [...this.allBlogs];
        blogs.sort((a, b) => {
          if (this.sorting == "newest") {
            if (a.creation_time > b.creation_time) {
              return -1;
            }
            if (a.creation_time < b.creation_time) {
              return 1;
            }
          } else if (this.sorting == "oldest") {
            if (a.creation_time < b.creation_time) {
              return -1;
            }
            if (a.creation_time > b.creation_time) {
              return 1;
            }
          }
          return 0;
        });
        this.allBlogs = blogs;
      } else {
        const blogs = [...this.filteredBlogs];
        blogs.sort((a, b) => {
          if (this.sorting == "newest") {
            if (a.creation_time > b.creation_time) {
              return -1;
            }
            if (a.creation_time < b.creation_time) {
              return 1;
            }
          } else if (this.sorting == "oldest") {
            if (a.creation_time < b.creation_time) {
              return -1;
            }
            if (a.creation_time > b.creation_time) {
              return 1;
            }
          }
          return 0;
        });
        this.filteredBlogs = blogs;
      }
    },
  },
  computed: {
    currentPageNr: {
      get() {
        return this.$store.getters["blog/getCurrentPageNr"];
      },
      set(val) {
        Logger.debug("currentPage set val", "Blogs.vue", val)();
        this.$store.dispatch("blog/goToPage", { pageNr: val });
      },
    },
    totalBlogs() {
      return this.$store.getters["blog/getTotalBlogs"];
    },
    totalPages() {
      return this.$store.getters["blog/getTotalPages"];
    },
    // blogsPerPage() {
    //   return this.$store.getters["blog/getBlogsPerPage"];
    // },
    currentPage() {
      return this.$store.getters["blog/getCurrentPage"];
    },
    blogCategories() {
      return this.$store.getters["blog1/getBlogCategories"];
    },
    allFilteredBlogs() {
      return this.$store.getters["blog1/getFilteredBlogs"];
    },
    blogBanner() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "blog_banner"
      );
    },
    showLoadMoreBtn() {
      if (this.isFilterActive) {
        return this.allFilteredBlogs.length > this.filteredBlogs.length;
      } else {
        return this.totalBlogs > this.currPage * this.blogsPerPage;
      }
    },
    blogSortingOption() {
      const sortOpt = [];
      config.blogSortingOption.forEach((element) => {
        const option = {
          value: element,
          text: this.$t(`blogSortingOption.${element}`),
        };
        sortOpt.push(option);
      });
      return sortOpt;
    },
  },
  watch: {
    sorting(currValue, oldValue) {
      if (currValue != oldValue) {
        this.sortBlogs();
      }
    },
    $route(newVal) {
      const {
        name,
        params: { category_id },
      } = newVal;

      if (name == "blog-category" && category_id) {
        this.getBlogsByCategoryId(category_id);
      } else {
        this.getAllBlogs();
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/base/scss/variables.scss";
@import "@/base/core/views/blog/blog.scss";
</style>
