<template>
  <div class="sidebar-holder">
    <b-link class="brands-opener d-lg-none" @click.prevent="toggleBrands"
      >Merken<span class="plus">+</span></b-link
    >
    <div
      class="brands-overlay"
      @click.prevent="toggleBrands"
      v-bind:class="{ show: brandsExpanded }"
    ></div>
    <div class="sidebar" v-bind:class="{ show: brandsExpanded }">
      <div class="d-lg-none brands-top">
        <span class="brands-collapse-heading">{{ $t("our_brands") }}</span>
        <i class="lnr lnr-cross" @click.prevent="toggleBrands"></i>
      </div>
      <span class="sidebar-heading"></span>
      <ul class="sidebar-links">
        <li
          v-for="(sidebarLink, index) in sidebarData"
          :key="'sidebarLink' + index"
        >
          <b-link :to="`/merken/` + sidebarLink.urlKey">{{
            sidebarLink.title
          }}</b-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarLinks",
  props: ["sidebarData"],
  data: () => ({
    brandsExpanded: false,
  }),
  mounted() {
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    toggleBrands() {
      this.brandsExpanded = !this.brandsExpanded;
    },
    resize() {
      if (window.innerWidth > 991) this.brandsExpanded = false;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
