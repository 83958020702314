<template>
  <section class="page-content">
    <SalePageContentFilter />
    <SaleFilteredProducts />
    <!--<b-link
      href="#"
      class="load-more d-block text-center font-weight-normal py-25"
      ><i class="lnr lnr-hourglass mr-6"></i>Meer laden</b-link
    >-->
  </section>
</template>

<script>
import SalePageContentFilter from "@/base/core/components/SalePageContentFilter";
import SaleFilteredProducts from "@/base/core/components/SaleFilteredProducts";
export default {
  name: "SalePageContent",
  components: {
    SalePageContentFilter,
    SaleFilteredProducts,
  },
};
</script>

<style lang="scss" scoped>
</style>
