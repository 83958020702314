<template>
  <div>
    <AdvertisingBanner class="col-12 my-30 mt-lg-0" />
    <!-- <BestSellers /> -->
    <SalePageContent />
  </div>
</template>

<script>
import SalePageContent from "@/base/core/components/SalePageContent";
import AdvertisingBanner from "@/base/core/components/products/category-product/AdvertisingBanner";
export default {
  name: "ProductsListingSale",
  components: {
    //BestSellers,
    SalePageContent,
    AdvertisingBanner,
  },
  computed: {},
};
</script>

<style lang="scss" scoped></style>
