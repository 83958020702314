<template>
  <div>
    <Breadcrumbs />
    <section class="static pt-7">
      <b-container>
        <b-row>
          <b-col lg="3" class="d-none d-lg-block">
            <div class="sidebar">
              <span class="sidebar-heading">Links</span>
              <cmsBlockSimple identifier="side-menu-cms" />
            </div>
          </b-col>
          <b-col lg="4">
            <h1>{{ getCmsData.title }}</h1>
            <v-runtime-template
              v-if="getCmsData.content"
              :template="`<div class='cms-content'>${getCmsData.content}</div>`"
            ></v-runtime-template>
          </b-col>
          <b-col lg="4">
            <section class="best-sellers">
              <span class="best-seller--category">{{
                $t("contact_form")
              }}</span>
              <div class="row">
                <div class="best-seller--card-holder">
                  <div v-if="contactForm != null">
                    <dynamic-forms type="contact" />
                  </div>
                </div>
              </div>
            </section>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import cmsBlockSimple from "@/base/core/components/core/BlockSimple";

import Breadcrumbs from "@/base/core/components/common/Breadcrumbs";
import VRuntimeTemplate from "v-runtime-template";
import DynamicForms from "@/base/core/components/DynamicForms";

export default {
  name: "Contact",
  components: {
    Breadcrumbs,
    cmsBlockSimple,
    VRuntimeTemplate,
    DynamicForms,
  },
  computed: {
    getCmsData() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "contact_page_block"
      );
    },
    contactForm() {
      return this.$store.getters["forms/getContactForm"];
    },
  },
  mounted() {
    const bcrumb = { current: "Contact", routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { name: "title", content: "Contact" },
        { name: "keywords", content: "Contact" },
        { name: "description", content: "Contact" },
      ],
      title: "Contact",
    };
  },

  data: () => ({
    sidebarData: {
      heading: "Contact",
    },
  }),
};
</script>
<style lang="scss">
</style>
