<template>
  <div>
    <div v-for="(productList, index) of productsChunks" :key="index">
      <ProductsSection :products-list="productList" />
      <!--<AdvertisingBanner class="col-12 mt-md--10 my-35" />-->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import ProductsSection from "@/base/core/components/products/category-product/ProductsSection";
//import AdvertisingBanner from "@/base/core/components/products/category-product/AdvertisingBanner";
import chunkArray from "@/base/helpers/chunkArray";

export default {
  name: "SaleFilteredProducts",
  components: {
    ProductsSection,
    //AdvertisingBanner,
  },
  computed: {
    productsChunks() {
      return chunkArray(this.$store.getters["sale/getShowProducts"], 12);
    },
  },
  methods: mapActions(["setProductStatus"]),
};
</script>

<style lang="scss" scoped></style>
