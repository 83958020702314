<template>
  <section class="page-content product-output-list">
    <SearchPageContentFilter />
    <div class="content-filter">
      <div class="cst-pagination-bar">
        <div class="pagination-bar-down">
          <div class="left">
            <div class="display-drop">
              <label>{{ $t("view") }}:</label>
              <select :value="prodsPerPage" @change="changePerPage">
                <option value="12">12</option>
                <option value="24">24</option>
                <option value="36">36</option>
              </select>
            </div>
          </div>
          <label class="dispaly-page"
            >Pagina<span>{{ currentPage }}</span
            >van<span>{{ Math.ceil(totalProducts / prodsPerPage) }}</span>
          </label>
        </div>

        <b-pagination
          :total-rows="totalProducts"
          :per-page="prodsPerPage"
          :value="currentPage"
          @change="changePage"
          class="cst-pagination"
          aria-controls="cat-item"
        >
          <template #page="{ page }">
            <b-link class="page-link" :to="goToPage(page)">
              {{ page }}
            </b-link>
          </template>
        </b-pagination>
      </div>
    </div>
    <SearchFilteredProducts />
    <div class="content-filter">
      <div class="cst-pagination-bar bottom-side border-0 mb-0 pb-0">
        <div class="pagination-bar-down">
          <div class="left">
            <div class="display-drop">
              <label>{{ $t("view") }}:</label>
              <select :value="prodsPerPage" @change="changePerPage">
                <option value="12">12</option>
                <option value="24">24</option>
                <option value="36">36</option>
              </select>
            </div>
          </div>
          <label class="dispaly-page"
            >Pagina<span>{{ currentPage }}</span
            >van<span>{{ Math.ceil(totalProducts / prodsPerPage) }}</span>
          </label>
        </div>

        <b-pagination
          :total-rows="totalProducts"
          :per-page="prodsPerPage"
          :value="currentPage"
          @change="changePage"
          class="cst-pagination"
          aria-controls="cat-item"
        >
          <template #page="{ page }">
            <b-link class="page-link" :to="goToPage(page)">
              {{ page }}
            </b-link>
          </template>
        </b-pagination>
      </div>
    </div>
    <!--<b-link
      href="#"
      class="load-more d-block text-center font-weight-normal py-25"
      ><i class="lnr lnr-hourglass mr-6"></i>Meer laden</b-link
    >-->
  </section>
</template>

<script>
import config from "@config";
import SearchPageContentFilter from "@/base/core/components/SearchPageContentFilter";
import SearchFilteredProducts from "@/base/core/components/SearchFilteredProducts";
export default {
  name: "SearchPageContent",
  components: {
    SearchPageContentFilter,
    SearchFilteredProducts,
  },
  mounted() {
    const { page } = this.$route.query;
    const { path } = this.$route;
    const query = { ...this.$route.query };
    if (page && this.totalPages < parseInt(page)) {
      delete query["page"];
    }

    const pageSize = config.products?.perPage;
    const perPage = this.$store.getters["search/getProductsPerPage"];
    if (perPage != pageSize) query["page-size"] = perPage;

    this.$router.replace({ path, query });
  },
  computed: {
    currentPage() {
      return this.$store.getters["search/getCurrentPage"];
    },
    prodsPerPage() {
      return this.$store.getters["search/getProductsPerPage"];
    },
    totalProducts() {
      return this.$store.getters["search/getTotalProducts"];
    },
  },
  methods: {
    changePage(pageNo) {
      this.$store.commit("search/setCurrentPage", pageNo);

      const { path } = this.$route;
      const query = { ...this.$route.query };

      if (pageNo != 1) query["page"] = pageNo;
      else delete query["page"];

      this.$router.replace({ path, query });
    },
    goToPage(page) {
      const { path } = this.$route;
      const query = { ...this.$route.query };

      if (page != 1) query["page"] = page;
      else delete query["page"];

      return { path, query };
    },
    changePerPage(event) {
      const perPage = +event.target.value;

      const { path } = this.$route;
      const query = { ...this.$route.query };

      const pageSize = config.products?.perPage;
      if (perPage != pageSize) query["page-size"] = perPage;
      else delete query["page-size"];
      delete query["page"];
      this.$router.replace({ path, query });

      this.$store.commit("search/setProductsPerPage", perPage);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
